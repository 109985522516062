._1d5QtSwZOwprVdjPssPV8u {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  text-align: center;
  padding: 25.6px;
  padding: 1.6rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

._33zOGaZ4e38U43m3lIR4bD {
  text-align: center;
  width: 100%;
  font-size: 12.8px;
  font-size: 0.8rem;
  line-height: 16px;
  line-height: 1rem;
  margin-bottom: 0;
}

._1g2BQlJ4RmQzO7sH0D5AxW {
  text-align: center;
  width: 100%;
  font-size: 22.4px !important;
  font-size: 1.4rem !important;
  margin-bottom: 6.4px !important;
  margin-bottom: 0.4rem !important;
  word-wrap: break-word;
}

._3JWHwui2MHYxH82VbMXz43 {
  width: auto;
  margin: 0 auto;
  border: none;
  padding: 0;
  background: none;
  font-size: 12.8px;
  font-size: 0.8rem;
}

._3JWHwui2MHYxH82VbMXz43:focus {
  outline: none;
}

._3JWHwui2MHYxH82VbMXz43:hover {
  text-decoration: underline;
}

._3JWHwui2MHYxH82VbMXz43:active {
  color: #cb5757;
}

